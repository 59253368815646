<template>

<div class="error content content-inner">
    
  <label class="message fw-800 fs-14 mt-3">Une erreur s'est produite, veuillez réessayer ultérieurement.</label>
  <div class="refresh d-flex justify-content-center flex-column align-items-center text-center">
    <div class="fs-18">
      <a href="/">
        <i class="mdi mdi-backup-restore"></i>
        <span class="ms-1">Dashboard</span>
      </a>
    </div>
  </div>

  </div>

</template>

<script>

export default {
  name: 'Error',
};
</script>

<style scoped>
.content{
  background-color: #FFFFFF !important;
}
.error.content-inner{
  width: 100%;
  padding: 0% !important;
}
.content{
  background: #FFFFFF !important;
  padding: 30px;
  top:0 !important;
}
.error .message
{
  color: red !important;
  display: flex;
  justify-content: center;
  text-align: center;
}
.error .refresh
{
  margin: 20% auto;
}
 
</style>


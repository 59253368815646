<template>

  <main class="main-content">

    <section id="" class="section bg-lightest angled lower-end">
      
      <div class="container py-30 text-center" v-auto-animate>
        
        <img class="width-30 py-30" src="/images/logo.png" alt="KSR Services" />

        <header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-gears boxed-icon fw-bold"></i></h2>
				</header>

        <div class="row align-items-center">
          <span>Votre demande de désinscription a bien été prise en compte.</span>
        </div>

        <div class="py-30">
          <a href="/">Visitez notre site web</a>
        </div>

      </div>

    </section>

  </main>

</template>

<script>

import { useRoute } from "vue-router";
import AOS from "aos";

export default {
  
  name: 'Unsubscribe',

  data () {
    return {
      contactCode : ""
    }
  },

  created() {
    this.contactCode = useRoute().params.code;
    console.log(this.contactCode);
    this.unsubscribe();
  },

  mounted() {
    AOS.init();
  },

  methods: {
    
    unsubscribe(){

      let unsubscribeReq = {
        method: "GET",
        headers: {"Content-Type": "application/json"}
      };

      fetch("/api/unsubscribe/"+this.contactCode, unsubscribeReq)
        .then( async response => {
          if (response.ok){
            
            return;
          }
          return Promise.reject(response);
          
        })
        .catch((response) => {
          console.error(response);
        });
    },

  },
};

</script>

<style scoped>

 
</style>


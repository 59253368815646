import { createWebHistory, createRouter } from "vue-router";

import Home       from '../components/Home.vue';
import Unsubscribe from '../components/Unsubscribe.vue'
import Error      from '../components/Error.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/unsubscribe/:code',
    name: "unsubscribe",
    component: Unsubscribe,
  },
  {
    path: '/error',
    name: "error",
    component: Error,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
